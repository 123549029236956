import React from 'react';
import { Header } from '../../Header';
import { Footer } from '../../Footer';
import {useTheme} from "@mui/material/styles";
import {useNavigate} from "react-router-dom";

export const MainLayout = ({ children }) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const backToHome = () => navigate('/')
    return (
        <div style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
            <Header theme={theme} url={backToHome} />
            <main style={{ flex: 1 }}>
                {children}
            </main>
            <Footer theme={theme} backToHome={backToHome} />
        </div>
    );
};

