import React from 'react';
import { TextField, Grid, useTheme, useMediaQuery } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setClientData } from '@services/reducers/basketSlice';

export const BasketContactForm = () => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const dispatch = useDispatch();

    const clientData = useSelector(state => state.basket.clientData);

    const handleChange = (e) => {
        const { name, value } = e.target;
        dispatch(setClientData({ [name]: value }));
    };

    return (
        <form>
            <Grid container spacing={4}>

                <Grid container item xs={12} sm={6} spacing={1}>

                    {/* Ім'я */}
                    <Grid item xs={12} sm={6} style={{ maxWidth: isSmallScreen ? '100%' : '50%' }}>
                        <TextField
                            size="small"
                            label="Ім'я"
                            variant="outlined"
                            fullWidth
                            style={{ fontSize: '0.8rem' }}
                            name="firstName"
                            value={clientData.firstName}
                            onChange={handleChange}
                        />
                    </Grid>

                    {/* Прізвище */}
                    <Grid item xs={12} sm={6} style={{ maxWidth: isSmallScreen ? '100%' : '50%' }}>
                        <TextField
                            size="small"
                            label="Прізвище"
                            variant="outlined"
                            fullWidth
                            style={{ fontSize: '0.8rem' }}
                            name="lastName"
                            value={clientData.lastName}
                            onChange={handleChange}
                        />
                    </Grid>

                    {/* Мобільний телефон */}
                    <Grid item xs={12} sm={6} style={{ maxWidth: isSmallScreen ? '100%' : '50%' }}>
                        <TextField
                            size="small"
                            label="Мобільний телефон"
                            variant="outlined"
                            fullWidth
                            style={{ fontSize: '0.8rem' }}
                            name="phone"
                            value={clientData.phone}
                            onChange={handleChange}
                        />
                    </Grid>

                    {/* Email */}
                    <Grid item xs={12} sm={6} style={{ maxWidth: isSmallScreen ? '100%' : '50%' }}>
                        <TextField
                            size="small"
                            label="Електронна пошта"
                            variant="outlined"
                            fullWidth
                            style={{ fontSize: '0.8rem' }}
                            name="email"
                            value={clientData.email}
                            onChange={handleChange}
                        />
                    </Grid>

                </Grid>

                <Grid container item xs={12} sm={6} spacing={1}>

                    {/* Адреса */}
                    <Grid item xs={12}>
                        <TextField
                            size="small"
                            label="Адреса"
                            variant="outlined"
                            fullWidth
                            style={{ fontSize: '0.8rem' }}
                            name="address"
                            value={clientData.address}
                            onChange={handleChange}
                        />
                    </Grid>

                    {/* Коментар */}
                    <Grid item xs={12}>
                        <TextField
                            size="small"
                            label="Коментар"
                            variant="outlined"
                            fullWidth
                            style={{ fontSize: '0.8rem' }}
                            name="comment"
                            value={clientData.comment}
                            onChange={handleChange}
                        />
                    </Grid>

                </Grid>

            </Grid>
        </form>
    );
};