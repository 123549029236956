// styledComponents.js
import styled from "@emotion/styled";
import { breakpoints, footerHeight } from "@helper/constants";
import Grid from "@mui/material/Unstable_Grid2";
import { Typography } from "@mui/material";
import { css } from "@mui/system";
import Divider from "@mui/material/Divider";
import { Link } from 'react-router-dom';

// Існуючі стилізовані компоненти...

export const CustomLink = styled(Link)`
    color: ${({ theme }) => theme.palette.primary.contrastText};
    &:hover {
        text-decoration: underline; /* Можеш додати ще ефект при наведенні */
    }
`;

export const StyledFooter = styled.div`
  background-color: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.primary.contrastText};
  padding: 16px;
  margin-top: 10px;
`;

export const WrapperFooterContent = styled.div`
    display: flex;
    flex-wrap: wrap; /* Додаємо flex-wrap для кращого обгортання елементів */
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;

    @media (max-width: ${breakpoints.mobile}) {
        flex-direction: column; /* Перехід у колонку */
        align-items: stretch;   /* Розтягнути всі елементи по ширині */
        justify-content: center;
    }
`;

export const WrapperFooterItem = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 10px;
    width: 33%; /* Для desktop версії */

    @media (max-width: ${breakpoints.mobile}) {
        align-items: center;
        margin: 10px 0;
        width: 100%; /* Кожен елемент займає всю ширину на мобільних пристроях */
    }
`;

export const DividerStyled = styled(Divider)`
    background-color: ${({ color }) => color};
    margin: 0 10px;

    @media (max-width: ${breakpoints.mobile}) {
        display: none;
    }
`;

export const StyledTypographyFooter = styled(({ bold, ...other }) => <Typography {...other} />)(
    ({ theme, bold }) => css({
        color: theme.palette.primary.contrastText,
        padding: "4px 0",
        fontWeight: bold ? 700 : 400,
    }),
);

export const StyledTypographyFooterLink = styled(({ bold, ...other }) => <Typography component={Link} {...other} />)(
    ({ theme, bold }) => css({
        color: theme.palette.primary.contrastText,
        padding: "4px 0",
        fontWeight: bold ? 700 : 400,
        textDecoration: "none",
        '&:hover': {
            textDecoration: "underline",
        },
    }),
);



export const WrapperCopyrightStyled = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
`;

export const RightSideStyled = styled.div`
  display: flex;
  align-items: flex-start;
    @media (max-width: ${breakpoints.mobile}) {
        flex-direction: column; /* Перехід у колонку */
        align-items: stretch;   /* Розтягнути всі елементи по ширині */
        justify-content: center;
    }
`;