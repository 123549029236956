import React, { useState } from 'react';
import { Grid, RadioGroup, FormControlLabel, Radio, Button, Typography, useTheme } from '@mui/material';
import { StyledTypographyFilter } from "@features/shared/styledComponents";
import { setDeliveryCharge, calculateProductsTotalPrice } from "@services/reducers/basketSlice";
import { useDispatch, useSelector } from "react-redux";

export const BasketPayment = () => {
    const [selectedValue, setSelectedValue] = useState('');
    const totalPrice = useSelector(state => state.basket.totalPrice);
    const basketItems = useSelector(state => state.basket.items);
    const deliveryCharge = useSelector(state => state.basket.deliveryCharge);
    const clientData = useSelector(state => state.basket.clientData);

    const theme = useTheme();
    const dispatch = useDispatch();

    const handleChange = (event) => {
        const { name, value } = event.target;

        setSelectedValue(event.target.value);
        if (event.target.value === 'delivery') {
            dispatch(setDeliveryCharge(100));
            console.log('Updated clientData:', { [name]: value });

        } else {
            dispatch(setDeliveryCharge(0));
        }
        dispatch(calculateProductsTotalPrice()); // Оновлюємо загальну вартість
    };

    const handlePayment = async () => {
        try {
            // Переконайтеся, що clientData має необхідні поля
            if (!clientData || !clientData.firstName || !clientData.lastName || !clientData.email || !clientData.phone) {
                alert('Будь ласка, заповніть контактні дані.');
                return;
            }

            // Збираємо дані для сервера
            const orderData = {
                // orderId: 'ORDER_' + Date.now(),
                orderId: 'DH1726416019',
                amount: (totalPrice + deliveryCharge).toFixed(2),
                currency: 'UAH',
                basketItems,
                clientData,
                deliveryCharge,
            };

            console.log('Order Data:', orderData);

            // Відправляємо запит на сервер для генерації paymentData
            const response = await fetch('/api/payment/one-click', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(orderData),
            });

            const paymentData = await response.json();

            // Перевірка на помилки
            if (paymentData.error) {
                console.error('Server error:', paymentData.error);
                alert('Помилка сервера: ' + paymentData.error);
                return;
            }

            console.log('Payment Data:', paymentData);

            // Створюємо та відправляємо форму
            const form = document.createElement('form');
            form.method = 'POST';
            form.action = 'https://secure.wayforpay.com/pay';
            form.acceptCharset = 'utf-8';

            Object.entries(paymentData).forEach(([key, value]) => {
                if (Array.isArray(value)) {
                    value.forEach(val => {
                        const input = document.createElement('input');
                        input.type = 'hidden';
                        input.name = `${key}[]`;
                        input.value = val;
                        form.appendChild(input);
                    });
                } else {
                    const input = document.createElement('input');
                    input.type = 'hidden';
                    input.name = key;
                    input.value = value;
                    form.appendChild(input);
                }
            });

            document.body.appendChild(form);
            form.submit();
        } catch (error) {
            console.error('Payment initiation failed:', error);
            alert('Помилка при ініціації платежу: ' + error.message);
        }
    };
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} container alignItems='center' justifyContent='center'>
                <RadioGroup row aria-label="delivery" name="delivery" value={selectedValue} onChange={handleChange}>
                    <FormControlLabel
                        value="pickup"
                        control={<Radio />}
                        label={<Typography color={theme.palette.primary.main} variant="body1">Самовивіз з адреси: Львів, вул. Наукова 43</Typography>}
                    />
                    <FormControlLabel
                        value="delivery"
                        control={<Radio />}
                        label={<Typography color={theme.palette.primary.main} variant="body1">Доставка в межах Львову (100 грн)</Typography>}
                    />
                </RadioGroup>
            </Grid>
            <Grid item xs={12} container alignItems='center' justifyContent='center'>
                <StyledTypographyFilter>Всього до оплати:</StyledTypographyFilter>
                <StyledTypographyFilter bold>{`${(totalPrice + deliveryCharge).toFixed(2)} грн`}</StyledTypographyFilter>
                <Button variant="contained" color="secondary" onClick={handlePayment}>
                    Оплатити
                </Button>
            </Grid>
        </Grid>
    );
};