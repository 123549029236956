import styled from "@emotion/styled";
import Grid from "@mui/material/Unstable_Grid2";
import { headerHeight, breakpoints } from '@helper/constants';
import Divider from "@mui/material/Divider";


export const StyledHeader = styled.div`
    color: ${({ theme }) => theme.palette.primary.main};
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2px 12px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); // Додаємо тінь знизу на 2 пікселі

    @media (max-width: ${breakpoints.mobile}) {
        height: calc(${headerHeight} + 120px);
        flex-direction: row;
        justify-content: center;
        align-items: start;
        padding: 0;
        display: flex;
    }
`;

export const BackToAdmin = styled.div`
  position: fixed;
  top: calc(${headerHeight} + 40px);
  right: 10px;
`;

export const WrapperHeaderLogoContainer = styled(Grid)`

    @media (max-width: ${breakpoints.mobile}) {
       width: unset;
    }
`;

export const Logo = styled.img`
    margin-right: 16px;
    width: 200px; 
    cursor: pointer;
    display: flex;
    justify-content: center;
`;

export const WrapperHeaderContent = styled(Grid)`
  display: flex;
  justify-content: flex-end;
  align-items: center;

    @media (max-width: ${breakpoints.mobile}) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        
    }
`;

export const WrapperHeaderContainer = styled(Grid)`

    @media (max-width: ${breakpoints.mobile}) {
        display: flex;
        justify-content: center;
       
    }
`;

export const StyledDivider = styled(Divider)`

    @media (max-width: ${breakpoints.mobile}) {
        display: none;
    }
`;
export const WrapperHeaderItem = styled(Grid)`
  margin: 0 10px;
   color: ${({ theme }) => theme.palette.primary.contrastText};
    @media (max-width: ${breakpoints.mobile}) {
        margin-top: 10px;
        display: inline-flex;
    }
`;