import { createSlice } from '@reduxjs/toolkit';

// Ініціалізація початкового стану
const initialState = {
    items: JSON.parse(localStorage.getItem('basketItems')) || [],
    status: 'idle',
    error: null,
    totalPrice: 0,
    deliveryCharge: 0,
    clientData: {
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        address: '',
        comment: '',
    },
};

const basketSlice = createSlice({
    name: 'basket',
    initialState,
    reducers: {
        addItem: (state, action) => {
            state.items.push(action.payload); // Використання push для додавання елемента до масиву items
        },
        removeItem: (state, action) => {
            state.items = state.items.filter(item => item.uniqueId !== action.payload.uniqueId);
        },

        updateItem: (state, action) => {
            const index = state.items.findIndex(item => item.uniqueId === action.payload.uniqueId);
            if (index !== -1) {
                state.items[index] = action.payload;
            }
        },
        calculateProductsTotalPrice: (state) => {
            state.totalPrice = state.items.reduce((total, item) => {
                return total + item.finalPrice;
            }, 0);

            state.totalPrice += state.deliveryCharge;
        },

        setDeliveryCharge: (state, action) => {
            state.deliveryCharge = action.payload;
        },

        setClientData: (state, action) => {
            state.clientData = { ...state.clientData, ...action.payload };
        },

    },
    extraReducers: (builder) => {
        // Тут можна додавати асинхронні actions (pending, fulfilled, rejected)
        // ...
    }
});

export const {
    addItem,
    removeItem,
    updateItem,
    calculateProductsTotalPrice,
    setDeliveryCharge,
    setClientData
} = basketSlice.actions;
export default basketSlice.reducer;
